import React from "react"
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';
import Layout from '../components/layout';


const Index = () => {
	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>{'A Drop of Delight'}</title>
				<html lang={'en'} />
				<meta name="description" content={'A Fundraiser for Just a Drop'} />
			</Helmet>
			<Layout />
		</>
  )   
}

export default Index

