import React from "react"
import Header from "./Header/Header"
import Home from "./Home/Home"
import Footer  from "./Footer/Footer"

import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"

const Layout = () => {
	const { myBackground } = useStaticQuery(
		graphql`
			query {
				myBackground: file(relativePath: { eq: "background.jpg" }) {
				childImageSharp {
					gatsbyImageData(
					quality: 90, 
					webpOptions: {quality: 100}, 
					width: 2000
					)
				}
				}
			}
		`
	)

	const pluginImage = getImage(myBackground);

	return (
		<BgImage image={pluginImage}>
			<Header />
			<Home />
			<Footer />
		</BgImage>
	)
}
export default Layout