import React from "react"
import { Container, Row, Col, Button, Carousel } from "react-bootstrap"
import { StaticImage } from 'gatsby-plugin-image'
import DonationTracker from "../DonationTracker/DonationTracker";

const Home = () => {
    return( 
        <div id="home">
            <Container>
                <Row className="homeLogo">
                    <Col>
                        <StaticImage src="../../images/logo.png" />
                    </Col>
                </Row>
                <Row className="homeDate">
                    <Col>
                        <div> Join us on Twitch on <u>Sep 19th, 2021</u> from <u>12:00 PM - 12:00 AM EST</u> for our 12-hour charity stream! </div>
                    </Col>
                </Row>
                <Row className="bgPanel">
                    <Col className="homeTracker">
                        <div style={{ fontSize: "1.5rem", paddingBottom: "10px" }}>Donation Goal</div>
                        <div className="tracker">
                            <div className="tracker-item"> 0 </div>
                            <div className="tracker-item2">
                                <DonationTracker />
                            </div>
                            <div className="tracker-item"> $10000 </div>
                        </div>
                        <Button className="donateButton"> 
                            <h1 className="centerText"> <a href="/donate/" style={{ textDecoration: 'none' }}> DONATE </a> </h1>
                        </Button>
                    </Col>
                </Row>
                <Row className="bgPanel">
                    <Col className="homeGoals">
                        <div className="homeTitle">
                            <h1> OUR GOALS </h1> 
                        </div>
                        <hr/>
                        <div className="homeText">
                            A Drop of Delight is a community led TF2 charity event, created in 2020, 
                            to help fundraise for clean water and proper sanitation for those in need. 
                            We will be partnering with <a className="styledLink" href="https://www.justadrop.org/" target="_blank" rel="noopener noreferrer">Just a Drop</a>, an international charity organization
                            providing safe, sustainable water supplies in Asia, Latin America, and Africa. 
                        </div>
                        <Col>
                            <Carousel className="goalsCarousel" interval={null} prevLabel={null} nextLabel={null} transition={null}>
                                <Carousel.Item >
                                    <StaticImage src="https://res.cloudinary.com/a-drop-of-delight/image/upload/v1630391936/goalsImages/1_qlfhpx.jpg" className="goalsImage" />
                                </Carousel.Item>
                                <Carousel.Item >
                                    <StaticImage src="https://res.cloudinary.com/a-drop-of-delight/image/upload/v1630391952/goalsImages/2_p4fklo.jpg" className="goalsImage" />
                                </Carousel.Item>
                                <Carousel.Item >
                                    <StaticImage src="https://res.cloudinary.com/a-drop-of-delight/image/upload/v1630391954/goalsImages/3_gayw17.jpg" className="goalsImage" />
                                </Carousel.Item>
                                <Carousel.Item >
                                    <StaticImage src="https://res.cloudinary.com/a-drop-of-delight/image/upload/v1630391956/goalsImages/4_n3jsbj.jpg" className="goalsImage" />
                                </Carousel.Item>
                                <Carousel.Item >
                                    <StaticImage src="https://res.cloudinary.com/a-drop-of-delight/image/upload/v1630391939/goalsImages/5_bftvet.jpg" className="goalsImage" />
                                </Carousel.Item>
                                <Carousel.Item >
                                    <StaticImage src="https://res.cloudinary.com/a-drop-of-delight/image/upload/v1630391936/goalsImages/6_hwaxed.jpg" className="goalsImage" />
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                    </Col>
                </Row>
                <Row className="bgPanel">
                    <Col className="homeSupport">
                        <div>
                            <h1> PLEASE SUPPORT US! </h1>
                        </div>
                        <hr/>
                        <div className="homeText supportText">
                            Please help us and show your support by donating to Just a Drop and their clean water project around the world! Click <a className="styledLink" href="/donate/">here</a> to check out more about their work!
                        </div>
                    </Col>
                </Row>
                <Row className="bgPanel">
                    <Col>
                        <Carousel className="giveawayCarousel" interval={null} prevLabel={null} nextLabel={null}>
                            <Carousel.Item >
                                <StaticImage src="../../images/sweepImages/6.png" className="giveawayImage" />
                                <StaticImage src="../../images/sweepImages/7.png" className="giveawayImage" />
                                <StaticImage src="../../images/sweepImages/5.png" className="giveawayImage" />
                            </Carousel.Item>
                            <Carousel.Item >
                                <StaticImage src="../../images/sweepImages/4.png" className="giveawayImage" />
                                <StaticImage src="../../images/sweepImages/2.png" className="giveawayImage" />
                                <StaticImage src="../../images/sweepImages/1.png" className="giveawayImage" />
                            </Carousel.Item>
                            <Carousel.Item >
                                <StaticImage src="../../images/sweepImages/8.png" className="giveawayImage" />
                                <StaticImage src="../../images/sweepImages/3.png" className="giveawayImage" />
                                <StaticImage src="../../images/sweepImages/9.png" className="giveawayImage" />
                            </Carousel.Item>
                        </Carousel>
                        <div className="homeText giveawayText">
                            In order to show our appreciation, we are hosting a giveaway where you can have a chance
                            to win a cool, class specific Circling Hearts Unusual! Click <a className="styledLink" href="/donate/">here</a> to learn more!
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Home;
